export const tituloPrincipal = {
  titulo: 'ALUMBRADO PUBLICO DE VILLA NUEVA, S.A',
  style: {
    font: {
      bold: true,
      size: 20,
      color: '#ffffff',
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#0070c0',
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true,
      width: 15,
    },
  },
}

export const tituloSecundario = {
  titulo: 'FALLAS DE LINEAS BT',
  style: {
    font: {
      bold: true,
      size: 20,
      color: '#ffffff',
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#0070c0',
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true,
    },
  },
}

export const subtitulo = {
  subtitulo: 'REPORTE GENERAL DE FALLAS DE LÍNEA BT',
  style: {
    font: {
      bold: true,
      size: 10,
      color: '#ffffff',
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#0070c0',
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true,
    },
  },
}

export const tituloDescarga = 'REPORTE GENERAL DE FALLAS BT'
export function getColumns() {
  return [
    {
      label: 'Correlativo',
      width: 11,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'No. Poste',
      width: 20,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Fecha Creación',
      width: 15,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Estado',
      width: 12,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Tipo Falla',
      width: 20,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Usuario',
      width: 15,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Coordenadas',
      width: 40,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Dirección',
      width: 50,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Solución Provisional',
      width: 50,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Dictamen',
      width: 50,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Recomendación',
      width: 35,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Observaciones',
      width: 50,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
  ]
}

export function getRows(rows) {
  const listado = []

  rows.forEach(row => {
    const item = {
      correlativo: {
        value: row.correlativo,
        type: 'number',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        }, // style que va tener la fila en el reporte
      },
      poste: {
        value: row.posteReferencia,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      fechaCreacion: {
        value: row.fechaCreacion,
        type: 'date',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      estado: {
        value: row.estado,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      tipoFalla: {
        value: row.tipoFalla.nombre,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      usuario: {
        value: row.usuario.nombre,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      coordenadas: {
        value: row.seguimiento.length > 0 ? `${row.seguimiento[0].localization.latitude}, ${row.seguimiento[0].localization.longitude}` : '',
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      direccion: {
        value: row.direccion ? row.direccion : '',
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      solucion: {
        value: row.solucionProvisional,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      dictamen: {
        value: row.dictamen,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      recomendacion: {
        value: row.recomendacion,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      observaciones: {
        value: row.observaciones,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
    }
    listado.push(item)
  })

  return listado
}
