<template>
  <div>
    <b-row>
      <b-col
        :cols="falla.tipoFalla.id === 2 ? 4 : 6"
      >
        <h6 class="text-primary">
          No. Poste
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="falla.posteReferencia"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        :cols="falla.tipoFalla.id === 2 ? 4 : 6"
      >
        <h6 class="text-primary">
          Tipo de Falla
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="falla.tipoFalla.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="falla.tipoFalla.id === 2"
        cols="4"
      >
        <h6 class="text-primary">
          No. Transformador
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="falla.noTransformador"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
      >
        <h6 class="text-primary">
          Usuario
        </h6>
        <b-form-group>
          <b-form-input
            id="Usuario"
            :value="falla.usuario.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
      >
        <h6 class="text-primary">
          Estado
        </h6>
        <b-form-group>
          <b-form-input
            id="Usuario"
            :value="falla.estado"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
      >
        <h6 class="text-primary">
          Latitud
        </h6>
        <b-form-group>
          <b-form-input
            id="Usuario"
            :value="falla.seguimiento.length > 0 ? falla.seguimiento[0].localization.latitude : ''"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
      >
        <h6 class="text-primary">
          Longitud
        </h6>
        <b-form-group>
          <b-form-input
            id="Usuario"
            :value="falla.seguimiento.length > 0 ? falla.seguimiento[0].localization.longitude : ''"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        sm="12"
      >
        <h6 class="text-primary">
          Dirección
        </h6>
        <b-form-group>
          <b-form-textarea
            rows="2"
            no-resize
            disabled
            :value="falla.direccion"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        sm="12"
      >
        <h6 class="text-primary">
          Solución Provisional
        </h6>
        <b-form-group>
          <b-form-textarea
            rows="2"
            no-resize
            disabled
            :value="falla.solucionProvisional"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        sm="12"
      >
        <h6 class="text-primary">
          Dictamen
        </h6>
        <b-form-group>
          <b-form-textarea
            rows="2"
            no-resize
            disabled
            :value="falla.dictamen"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="12"
      >
        <h6 class="text-primary">
          Recomendación
        </h6>
        <b-form-group>
          <b-form-textarea
            rows="2"
            no-resize
            disabled
            :value="falla.recomendacion"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="12"
      >
        <h6 class="text-primary">
          Observaciones
        </h6>
        <b-form-group>
          <b-form-textarea
            rows="2"
            no-resize
            disabled
            :value="falla.observaciones"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="falla.estado === 'FINALIZADA'">
      <b-col
        cols="12"
        sm="12"
      >
        <h6 class="text-primary">
          Seguimiento
        </h6>
        <vue-good-table
          :columns="column"
          :rows="[falla.finalizacion]"
        />
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="12"
        sm="12"
      >
        <h6 class="text-primary">
          Tracking
        </h6>
        <vue-good-table
          :columns="columns"
          :rows="falla.seguimiento"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'detalle'">
              <ImagenShowReemplazo
                :posicion-imagen="posicionImagen"
                :ticket="objetoTicket"
                :tipo-obj="'Array'"
                :clave="'seguimiento'"
                :origen="origenes.fallasBt"
                @modalAbierto="mostrarImagen(props.index)"
              />
              <p v-if="props.row.comentarios">{{ props.row.comentarios }}</p>
            </span>
            <span v-else-if="props.column.field == 'fechaHora'">
              <span>{{ formatFecha(props.row.fechaHora) }}</span>
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormInput, BFormGroup, VBModal, BFormTextarea,
} from 'bootstrap-vue'
import { calcularFecha } from '@/utils/fechas'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { origenes } from '@/utils/origenesImagen'
import ImagenShowReemplazo from '@/components/componenteImagenActualizar/ImagenShowReemplazo.vue'

export default {
  components: {
    ImagenShowReemplazo,
    BFormTextarea,
    BCol,
    BRow,
    BFormInput,
    BFormGroup,
    VueGoodTable,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    falla: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      origenes,
      posicionImagen: 0,
      objetoTicket: this.falla,
      columns: [
        {
          label: 'Tipo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha/Hora',
          field: 'fechaHora',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      column: [
        {
          label: 'Tipo',
          field: 'tipo.nombre',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Comentarios',
          field: 'comentarios',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Correlativo',
          field: 'correlativo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
    }
  },
  methods: {
    mostrarImagen(index) {
      this.posicionImagen = index
    },
    formatFecha(row) {
      return calcularFecha(row)
    },
  },
}
</script>

<style scoped>

</style>
